  
  
  .theme--red {
      background-image: url(images/BG.png);
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      height: 100vh;

  }
  
  .theme--red.popup {
    filter: blur(1.5px);
  }

  
  @keyframes gradient {
      0% {
          background-position: 0% 50%;
      }
      50% {
          background-position: 100% 50%;
      }
      100% {
          background-position: 0% 50%;
      }
  }
  
  .main {
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -moz-box-align: center;
      -moz-box-pack: center;
      -moz-box-flex: 1;
      align-items: center;
      display: -moz-box;
      display: flex;
      flex: 1 1 0;
      flex-direction: column;
      justify-content: center;
      max-width: 1200px;
      min-height: -webkit-min-content;
      min-height: -moz-min-content;
      min-height: min-content;
      padding: 3.125rem 0;
      width: 100%
  }
  
  .footer {
      -moz-box-flex: 0;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      flex: 0 1 auto;
      font-size: .75rem;
      padding: .3125rem;
      text-align: center;
      width: 100%
  }
  
  a {
      color: hsla(0, 0%, 100%, .8);
      text-decoration: none
  }
  
  a:active,
  a:focus,
  a:hover {
      color: hsla(0, 0%, 100%, .9);
      text-decoration: underline
  }
  
  .align--right {
      text-align: right
  }
  
  .solid-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: #120e15;
      border: none;
      border-radius: .625rem;
      box-shadow: .625rem .625rem 0 rgba(0, 0, 0, .2);
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-family: inherit;
      font-size: 1.75rem;
      font-weight: 700;
      line-height: 1.75rem;
      outline: none;
      padding: 1rem .625rem;
      text-align: center;
      text-decoration: none;
      white-space: nowrap
  }
  
  .solid-button:hover {
      background-color: #ffc639
  }
  
  .solid-button:disabled {
      cursor: not-allowed
  }
  
  .solid-button:disabled:hover {
      background-color: #ffbf1f
  }
  
  .solid-button--danger {
      background-color: #e54c23;
      color: #fff
  }
  
  .solid-button--danger:hover {
      background-color: #eeaa91!important;
  }
  
  .solid-button--danger:disabled {
      cursor: not-allowed
  }
  
  .solid-button--danger:disabled:hover {
      background-color: #e54c23
  }
  
  .icon-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-color: transparent;
      border: 0;
      border-radius: 0;
      box-shadow: none;
      color: hsla(0, 0%, 100%, .8);
      cursor: pointer;
      display: inline-block;
      font-family: inherit;
      font-size: 2.5rem;
      line-height: 1em;
      margin: 0;
      outline: none;
      padding: 0;
      text-align: center;
      text-decoration: none
  }
  
  .icon-button>svg {
      display: block;
      height: 1em;
      width: 1em
  }
  
  .icon-button:hover {
      color: hsla(0, 0%, 100%, .9)
  }
  
  .icon-button:disabled {
      cursor: not-allowed
  }
  
  .icon-button:disabled:hover {
      color: hsla(0, 0%, 100%, .9)
  }
  
  .icon-button--small {
      font-size: 1.5rem
  }
  
  .input-group {
      margin: 0 0 2rem
  }
  
  .input-group--2-column {
      -moz-box-pack: justify;
      -moz-box-align: center;
      align-items: center;
      display: -moz-box;
      display: flex;
      justify-content: space-between
  }
  
  .input-group--2-column .input-label {
      margin: 0
  }
  
  .input-label {
      display: inline-block;
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1.5rem
  }
  
  .input-field {
      background-color: white;
      border: none;
      border-radius: .25rem;
      box-shadow: .625rem .625rem 0 rgba(0, 0, 0, .2);
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      color: #000;
      font-family: inherit;
      font-size: 1.5rem;
      line-height: 1.25em;
      outline: none;
      padding: 1.875rem;
      width: 100%
  }
  
  .input-field:-moz-read-only {
      background-color: #e8e8e8;
      border: none;
      color: #000;
      cursor: not-allowed
  }
  
  .input-field:read-only {
      background-color: #e8e8e8;
      border: none;
      color: #000;
      cursor: not-allowed
  }
  
  .input-field::placeholder {
      color: #999
  }
  
  .input-field::-webkit-input-placeholder {
      color: #999
  }
  
  .input-field:-moz-placeholder,
  .input-field::-moz-placeholder {
      color: #999
  }
  
  .input-field:-ms-input-placeholder {
      color: #999
  }
  
  .input-field::-ms-input-placeholder {
      color: #999
  }
  
  .input-field:-webkit-autofill,
  .input-field:-webkit-autofill:active,
  .input-field:-webkit-autofill:focus,
  .input-field:-webkit-autofill:hover {
      -webkit-text-fill-color: #000 !important;
      background-clip: content-box !important;
      -webkit-box-shadow: inset 0 0 0 3.125rem #e8e8e8 !important
  }
  
  .input-field--textarea {
      max-width: 100%;
      min-width: 100%;
      resize: vertical
  }
  
  .input--switch {
      -moz-box-flex: 0;
      border-radius: 30px;
      box-shadow: .4375rem .4375rem 0 rgba(0, 0, 0, .2);
      display: inline-block;
      flex: 0 0 75px;
      height: 30px;
      margin: 0 0 0 1rem;
      position: relative;
      width: 75px
  }
  
  .input--switch .slider {
      background-color: #e8e8e8;
      border-radius: 30px;
      bottom: 0;
      cursor: pointer;
      left: 0;
      right: 0;
      top: 0
  }
  
  .input--switch .slider,
  .input--switch .slider:before {
      position: absolute;
      -o-transition: .4s;
      -moz-transition: .4s;
      transition: .4s
  }
  
  .input--switch .slider:before {
      background-color: #666;
      border-radius: 24px;
      bottom: 3px;
      content: "";
      height: 24px;
      left: 3px;
      width: 24px
  }
  
  .input--switch input {
      height: 0;
      opacity: 0;
      width: 0
  }
  
  .input--switch input:checked+.slider:before {
      -webkit-transform: translateX(45px);
      -moz-transform: translateX(45px);
      -o-transform: translateX(45px);
      transform: translateX(45px)
  }
  
  .theme--red .input--switch input:checked+.slider:before {
      background-color: #f76d6d
  }
  
  .theme--purple .input--switch input:checked+.slider:before {
      background-color: #331e90
  }
  
  #lucky-draw {
      text-align: center;
      z-index: 2
  }
  
  #lucky-draw,
  .title {
      position: relative;
      width: 100%;
      margin-top:2.5rem;
  }
  
  .title {
      height: auto;
      margin: 1.75rem 0 4.875rem;
      max-width: 31rem;
      z-index: 4
  }
  
  @media (max-width:1599px) {
      .title {
          margin: 1.75rem 0 3.9rem;
          max-width: 24.8rem
      }
  }
  
  @media (max-width:1199px) {
      .title {
          margin: 1.75rem 0 3.2175rem;
          max-width: 20.46rem
      }
  }
  
  @media only screen and (min-width:576px) and (max-width:767px) {
      .title {
          margin: 1.75rem 0 2.4375rem;
          max-width: 15.5rem
      }
  }
  
  @media only screen and (max-width:575px) {
      .title {
          margin: 1.75rem 0 1.404rem;
          max-width: 8.928rem
      }
  }
  
  .control-group {
      position: absolute;
      right: 2.5rem;
      top: 0;
      z-index:9;
  }
 
.control-left-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
}
.control-left-footer>img{
    width: 370px;
    margin-bottom: -5px;
}


  
  .control-right-footer {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 1;

  }
  
  .control-right-footer>img{
      width: 460px;
      margin-bottom: -5px;
  }


  @media (max-width:1199px) {
    .control-right-footer>img{
        width: 360px;
        margin-bottom: -5px;
    }
    .control-left-footer>img{
        width: 300px;
        margin-bottom: -5px;
    }
  }
  @media (max-width:860px) {
    .control-right-footer>img{
        display: none;
    }
    .control-left-footer>img{
         display: none;
    }
    
  }



  .footer .control-center{
        position: absolute;
        bottom: 0;
        left: 0;
        
  }
  .footer .control-center>img{
        margin-bottom: -5px;
        width: 100%;
}

img#bg-center-wide{
    display: none;
}


@media (min-width:1450px) {
   img#bg-center{
        display: none;
    }
    img#bg-center-wide{
        display: block;
    }  
}


#lucky-draw>img {
    width: 35%;
    /* margin-top: -105px; */
    margin-bottom: -30px;  }

    
@media (max-height:760px) {
    #lucky-draw>img{
        /* margin-top: -100px; */
        margin-bottom:-30px;
    }
    
}
   
@media (max-height:650px) {
    #lucky-draw>img{
        /* width: 60%; */
        /* margin-top: 10px; */
        margin-bottom:-30px;
    }
    
}


@media (max-width:1000px) {
    #lucky-draw>img{
        width: 45%;
        margin-top: 0;
        margin-bottom:0;
    }
    
}

@media (max-width:700px) {
    #lucky-draw>img{
        width: 65%;
    }
    
}

@media (max-width:500px) {
    #lucky-draw>img{
        width: 75%;
    }
    
}



    
@media (min-width:2200px) {
    img#bg-center, img#bg-center-wide{
        display: none;
    }
}

      
    
@media (max-width:600px) {
    #lucky-draw>img{
        margin-top: 0;
        margin-bottom:30px;
    }
    .footer .control-center>img{
        display: none;
    }
    
  }

  
  .control-group .icon-button {
    margin: 6.5rem 0.5rem 0 0;
  }
  @media only screen and (max-width:1200px) {
    .control-group .icon-button {
        margin: 5.5rem 0 0 0;
      }
    }

    @media only screen and (max-width:970px) {
        .control-group .icon-button {
            margin: 3.5rem 0 0 0;
        }
    }
    


  .control-left {
    position: absolute;
    top: 6.5rem;
    left: 3.5rem;
    z-index: 9999;
  }
  @media only screen and (max-width:1200px) {
    .control-left {
        top: 5rem;
        left: 2.5rem;
    }
  }

  @media only screen and (max-width:970px) {
    .control-left {
        top: 3rem;
        left: 2.5rem;
    }
  }

  
  /* @media only screen and (min-width:1270px) {
    .control-group .icon-button { 
        margin: 7rem 2.5rem 0 0;
    }
}
@media only screen and (min-width:1400px) {
    .control-group .icon-button { 
        margin: 6rem 4.5rem 0 0;
    }
} */


  .slot {
      position: relative;
      margin: 5rem auto 0 auto;
  }
  
  .slot__outer {
      /* background: #ffbf1f url(./assets/images/light-blubs.svg); */
      /* background: #100f0e url(./assets/images/frame.png); */
      background: url(images/display-box.svg);
      background-size: 100%;
      /* box-shadow: .625rem .625rem 0 rgba(0, 0, 0, .2); */
      height: 11rem;
      margin: 0 auto;
      padding: 1.625rem;
      width: 62.5rem;
      z-index: 3
  }
  
  .slot__inner,
  .slot__outer {
      border-radius: 1.25rem;
      position: relative
  }
  
  .slot__inner {
      /* background-color: #fff; */
      height: 100%;
      overflow: hidden;
      padding: 1.8125rem;
      width: 100%
  }
  
  .slot__inner {
      overflow: hidden;
      width: 100%;
      height: 125px;
      top: -5px;
      /* top: 28px; */
  }
    
  @media (max-width:1599px) {
      .slot {
          /* height: 11.5rem; */
          /* margin: 0 auto; */
          margin-bottom: -2rem;
          width: 50rem
      }
  
      .slot__outer {
          -webkit-transform: scale(.8);
          -moz-transform: scale(.8);
          -o-transform: scale(.8);
          transform: scale(.8);
          -webkit-transform-origin: top left;
          -moz-transform-origin: top left;
          -o-transform-origin: top left;
          transform-origin: top left
      }
  }
  
  @media (max-width:1199px) {
      .slot {
          height: 7rem;
          margin: 3rem auto 0 auto;
          width: 41.25rem
      }
  
      .slot__outer {
          -webkit-transform: scale(.66);
          -moz-transform: scale(.66);
          -o-transform: scale(.66);
          transform: scale(.66);
          -webkit-transform-origin: top left;
          -moz-transform-origin: top left;
          -o-transform-origin: top left;
          transform-origin: top left
      }
  }
  
  @media only screen and (min-width:576px) and (max-width:767px) {
      .slot {
          height: 7.1875rem;
          margin-bottom: -2rem;
          width: 31.25rem
      }
  
      .slot__outer {
          -webkit-transform: scale(.5);
          -moz-transform: scale(.5);
          -o-transform: scale(.5);
          transform: scale(.5);
          -webkit-transform-origin: top left;
          -moz-transform-origin: top left;
          -o-transform-origin: top left;
          transform-origin: top left
      }
  }
  
  @media only screen and (max-width:575px) {
      .slot {
          height: 4.14rem;
          margin: 0 auto;
          width: 25.5rem
      }
  
      .slot__outer {
          -webkit-transform: scale(.40);
          -moz-transform: scale(.40);
          -o-transform: scale(.40);
          transform: scale(.40);
          -webkit-transform-origin: top left;
          -moz-transform-origin: top left;
          -o-transform-origin: top left;
          transform-origin: top left
      }
  }
  
  @media only screen and (max-width:450px) {
      .slot {
          height: 4.14rem;
          margin: 0 auto;
          width: 25rem
      }
  
      .slot__outer {
          -webkit-transform: scale(.4);
          -moz-transform: scale(.4);
          -o-transform: scale(.4);
          transform: scale(.4);
          -webkit-transform-origin: top left;
          -moz-transform-origin: top left;
          -o-transform-origin: top left;
          transform-origin: top left
      }
  }
  @media only screen and (max-width:400px) {

    .slot__outer {
        -webkit-transform: scale(.365);
        -moz-transform: scale(.365);
        -o-transform: scale(.365);
        transform: scale(.365);
        -webkit-transform-origin: top left;
        -moz-transform-origin: top left;
        -o-transform-origin: top left;
        transform-origin: top left
    }
}

@media only screen and (max-width:360px) {

    .slot__outer {
        -webkit-transform: scale(.33);
        -moz-transform: scale(.33);
        -o-transform: scale(.33);
        transform: scale(.33);
        -webkit-transform-origin: top left;
        -moz-transform-origin: top left;
        -o-transform-origin: top left;
        transform-origin: top left
    }
}


  
  .reel {
      width: 100%
  }
  
  .reel>div {
      color: #000;
      font-size: 3.75rem;
      font-weight: 700;
      height: 7.5rem;
      /* line-height: 7.5rem; */
      line-height: 1;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      -webkit-transform: translateZ(0);
      -moz-transform: translateZ(0);
      transform: translateZ(0);
      white-space: nowrap
  }
  
  #draw-button {
      margin: 0 0 0;
      width: 22.5rem;
      z-index: 5
  }
  
  img#draw-button{
      width: 160px!important;
  }
  
  @media (max-width:575px) {
      img#draw-button {
          width: 150px!important;
          margin: 10px;
      }
  
  }
  
  
  #draw-button-hover {
      /* margin-left: 20px; */
      width: 22.5rem;
      z-index: 5
  }
  
  img#draw-button-hover{
      width: 160px!important;
  }
  
  @media (max-width:575px) {
      img#draw-button-hover{
          width: 150px!important;
          margin: 10px;
      }
  
  }
  
  .settings {
      background-color: rgba(0, 0, 0, .6);
      content: " ";
      display: none;
      z-index: 9
  }
  
  .settings,
  .settings__panel {
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;
      width: 100%
  }
  
  .settings__panel {
      -moz-box-orient: vertical;
      -moz-box-direction: normal;
      -moz-box-pack: justify;
      -webkit-overflow-scrolling: auto;
      display: -moz-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 33.125rem;
      overflow: auto;
      padding: 3.125rem 2.75rem 0;
      z-index: 9999;
  }
  
  .settings__panel__group:last-child {
      padding-bottom: 3.125rem
  }
  
  @media only screen and (max-width:575px) {
      .settings__panel {
          padding: 10% 10% 0
      }
  
      .settings__panel__group:last-child {
          padding-bottom: 10%
      }
  }
  
  .settings__title {
      font-size: 2rem;
      font-weight: 700;
      margin: 0 0 1.875rem
  }
  
  .settings #settings-close {
      display: block;
      margin: 0;
      width: 100%
  }
  
  .theme--red .settings__panel {
      background-color: #92cd4f;
      color: #fff
  }
  
  .theme--purple .settings__panel {
      background-color: #331e90;
      color: #fff;
      z-index:999999;
  }
  
  .sunburst {
      display: none;
      height: 100vh;
      margin: -50vh 0 0 -50vw;
      overflow: hidden;
      pointer-events: none;
      width: 100vw;
      z-index: 0
  }
  
  .sunburst,
  .sunburst>img {
      left: 50%;
      position: absolute;
      top: 50%
  }
  
  .sunburst>img {
      -webkit-animation: rotate 10s linear infinite;
      -moz-animation: rotate 10s linear infinite;
      -o-animation: rotate 10s linear infinite;
      animation: rotate 10s linear infinite;
      height: auto;
      margin: -50vmin 0 0 -50vmin;
      width: 100vmin
  }
  
  .confetti {
      height: 100%;
      left: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1
  }
  .icon-button>img {
      width: 40px;
  }
  
  .snowflake {
      color: #fff;
      font-size: 1em;
      font-family: Arial;
      text-shadow: 0 0 1px #000;
    }
    
    @-webkit-keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@-webkit-keyframes snowflakes-shake{0%{-webkit-transform:translateX(0px);transform:translateX(0px)}50%{-webkit-transform:translateX(80px);transform:translateX(80px)}100%{-webkit-transform:translateX(0px);transform:translateX(0px)}}@keyframes snowflakes-fall{0%{top:-10%}100%{top:100%}}@keyframes snowflakes-shake{0%{transform:translateX(0px)}50%{transform:translateX(80px)}100%{transform:translateX(0px)}}.snowflake{position:fixed;top:-10%;z-index:9;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;cursor:default;-webkit-animation-name:snowflakes-fall,snowflakes-shake;-webkit-animation-duration:10s,3s;-webkit-animation-timing-function:linear,ease-in-out;-webkit-animation-iteration-count:infinite,infinite;-webkit-animation-play-state:running,running;animation-name:snowflakes-fall,snowflakes-shake;animation-duration:10s,3s;animation-timing-function:linear,ease-in-out;animation-iteration-count:infinite,infinite;animation-play-state:running,running}.snowflake:nth-of-type(0){left:1%;-webkit-animation-delay:0s,0s;animation-delay:0s,0s}.snowflake:nth-of-type(1){left:10%;-webkit-animation-delay:1s,1s;animation-delay:1s,1s}.snowflake:nth-of-type(2){left:20%;-webkit-animation-delay:6s,.5s;animation-delay:6s,.5s}.snowflake:nth-of-type(3){left:30%;-webkit-animation-delay:4s,2s;animation-delay:4s,2s}.snowflake:nth-of-type(4){left:40%;-webkit-animation-delay:2s,2s;animation-delay:2s,2s}.snowflake:nth-of-type(5){left:50%;-webkit-animation-delay:8s,3s;animation-delay:8s,3s}.snowflake:nth-of-type(6){left:60%;-webkit-animation-delay:6s,2s;animation-delay:6s,2s}.snowflake:nth-of-type(7){left:70%;-webkit-animation-delay:2.5s,1s;animation-delay:2.5s,1s}.snowflake:nth-of-type(8){left:80%;-webkit-animation-delay:1s,0s;animation-delay:1s,0s}.snowflake:nth-of-type(9){left:90%;-webkit-animation-delay:3s,1.5s;animation-delay:3s,1.5s}
    /* Demo Purpose Only*/
    .demo {
      font-family: 'Raleway', sans-serif;
        color:#fff;
        display: block;
        margin: 0 auto;
        padding: 15px 0;
        text-align: center;
    }
    .demo a{
      font-family: 'Raleway', sans-serif;
    color: #000;		
    }
  
    .snowflake>img {
      width: 10px;
    }

    .snowflake.c2>img {
        width: 30px;
        transform: rotate(46deg);
    }

    .snowflake.c3>img {
        width: 15px;
        transform: rotate(221deg);
    }

    .snowflake.c4>img {
        width: 8px;
        transform: rotate(274deg);
    }
    .snowflake.c5>img {
        width: 20px;
        transform: rotate(141deg);
    }
    .snowflake.c6>img {
        width: 9px;
        transform: rotate(323deg);
    }
    .snowflake.c7>img {
        width: 10px;
        transform: rotate(185deg);
    }
    .snowflake.c9>img {
        width: 22px;
        transform: rotate(275deg)
    }

    .snowflake.c10>img {
        width: 15px;
        transform: rotate(10deg);
    }







  
  
  h1.header {
      color: white;
      font-size: 60px;
      font-weight: 800;
      margin: 0;
  }
  span.header-red {
      background: -webkit-linear-gradient(145deg, #df0814, #201515 90%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
  }
  
  h2.sub-header {
      font-size: 30px;
      margin-top: -15px;
      color: white;
      font-weight: 500;
  }
  .solid-button:hover {
      background-color: #402957;
  }
  /* @media (max-width:800px) {
      #lucky-draw>img { 
          width: 100%;
          margin-top: -200px;
          margin-bottom: -60px;
      }
  } */
  
  
  p.remain{
      font-size: 18px;
      color: white;
      padding: 0;
      margin: 20px 0;
  }
  
  #remain{
      font-size: 18px;
      color: white;
      padding: 0;
      margin: 20px 0;
  }
  #popup{
      z-index: 999999;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      /* height: 470px; */
      background-image: url(images/winnerBox.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      width: 65%;
      height: 100%;

  }
  
  /* #popup>img{
      width: 1100px;
      position: relative;
  } */
  @media (min-width:1200px) {
      #popup>img{
          width: 900px;
      }
  }
  
  @media (max-width:1199px) {
      #popup>img{
          width: 800px;
      }
  }
  
  
  @media (max-width:1000px) {
      #popup>img{
          width: 800px;
      }
  }
  
  @media (max-width:800px) {
      #popup>img{
          width: 100%;
      }
  }
  
  #popup .centered{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99999999;
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      width: 75%;
  }
  
  #popup button.top-right {
      position: absolute;
      top: 11.5rem;
      right: 9rem;
      z-index: 999999999;
      border: 0;
      background: none;
      cursor: pointer;
      font-size: 25px;
      color: rgba(0, 0, 0, 0);
      top: 0;
      padding: 3.5rem;
      right: 0;
      height: 100%;
  }

  #popup button.top-right img{
    width: 35px;
}
  
  
  img#logo-techx{
      width: 55px;
      position: relative;
  }
  .control-left-lantern {
      position: absolute;
      left: 0;
      top: 5rem;
      z-index: 1;
  }
  
  img#lantern-left{
      width: 100px;
      filter: blur(1.5px);
  }
  .control-right-lantern {
    position: absolute;
    right: 50px;
    top: 0;
}

.control-right-lantern-small {
    position: absolute;
    left: 0;
    right: 0;
    top: -8.5%;
}

@media (min-width:1600px) {
    .control-right-lantern-small {
        top: -15%;
    }
    
}


@media (max-height:705px) {
    .control-right-lantern-small {
        display: none;    
    }
    
}

.control-footer {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

.control-footer img{
    width: 100%;
    position: fixed;
    bottom: 0;
}

img#lantern-right{
    width: 100px;
}

img#lantern-right-small{
    width: 100%;
}
  
  .group-text{
      color: white;
      float: right;
      margin: 7px 10px;
  }
  .triangle{
      background: url(./images/triangle.svg);
      background-size: 13px;
      background-repeat: no-repeat;
      background-position-y: center;
      margin: 5px;
      padding: 0 10px;
  }
  
  .styled-option{
      text-align: center;
      color: #4f4f4f;
      list-style: none;
      padding: 12px 16px;
      cursor: default;
      width: 150px;
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 4px;
      font-size: 16px;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url(../../assets/images/polygon.png);
      background-size: 18px;
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 95%;
      margin-bottom: 22px;  
  }
  
  .prizes-text{    
      float: left;
      margin: 0 30px 0 0;
  }
  
  .settings #settings-save{
      width: 165px;
      background: #ffd200;
      color: black;
      margin: -5px;
      float: right;
  }
  .settings #settings-save:hover{
    background: #efad1c;
}

  
  .group-header{
      display: block;
      padding-bottom: 30px;
  }
  .group-header .settings__title{
      display: inline;
  }
  .group-text .remaining-text{
      color:#FFD809;
      font-size:20px;
      font-weight: 700;
      margin:0;
  }
  .group-challengers{
      display: block;
      margin-bottom: 20px;
  }
  #challengers-text{
      display: inline;
  }
  .group-text .remaining-text p.challengers-text{
      display: block;
      font-size: 14px;
      margin: 0;
      color: white;
  }
  
  #remaining-text{    
      color: white;
      float: right;
      margin: 5px;
  }
  #remaining-text h3{
      margin: 0;
  } 
  
  #winner-1{
      font-size: 5vw;
      padding-top: 1rem;
  }
  
  #winner-2{
      font-size: 4vw;
      padding-top: 5rem;
  }
  @media (min-width:1300px) {
      #winner-2{
          font-size: 3.5vw;
      }
  }
  
  #winner-3{    
      font-size: 3.5vw;
      padding-top: 4rem;
  }
  
  #winner-2 div{
      margin-bottom: 40px;
  }
  
  #winner-3 div,#winner-4 div{    
      margin-bottom: 20px;
  }
  
  #winner-4{
      font-size: 3.5vw;
      padding-top: 5rem;
  }
  
  #winner-5{
      font-size: 2.5vw;
      padding-top: 4rem;
  }
  #winner-5 div{
      margin-bottom: 15px;
  }
  #winner-10{
    font-size: 1.5vw;
    padding-top: 100px;
}
    #winner-10 div{
        margin-bottom: 0;
    }
    @media (max-width:1200px) {
        #winner-10{
            padding-top: 5rem;
        }
        
    }
    #winner-11{
        font-size: 1.4vw;
        padding-top: 100px;
    }
        #winner-11 div{
            margin-bottom: 0;
        }
        @media (max-width:1200px) {
            #winner-11{
                padding-top: 5rem;
            }
            
        }
    
  
  @media (min-width:1500px) {
      #winner-3{
          font-size: 3vw;
      }
      #winner-4{
          font-size: 3vw;
      }
  }
  
  @media (min-width:1800px) {
      #winner-3{
          font-size: 2.25vw;
      }
      #winner-4, #winner-5{
          font-size: 2vw;
      }
  }
  
  
  @media (max-width:650px) {
      #winner-2,#winner-3{
          padding-top: 3rem;
      }
      #winner-2 div{ 
          margin-bottom: 20px;   
      }
      #winner-3 div,#winner-4 div{ 
          margin-bottom: 10px;   
      }
  }
  
  
  .btn-spin {
      position: relative;
      display: inline-block;
      margin: 30px 0;
      cursor: pointer;
  }
  .btn-spin .spin-hover {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99;
  }
  .btn-spin:hover .spin-hover {
      display: inline;
  }
 .arrow-spin{
    width: 20px;
    margin: 0 15px 18px 0;
 }
 @media only screen and (max-width:575px) {
    .arrow-spin{        
        margin: 0 5px 25px 0;
    }
}
