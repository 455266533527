body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus,
textarea:focus,
button:focus,
[contenteditable='true']:focus {
  outline: none;
}

:focus{
  outline: none; /* no outline - for most browsers */
  box-shadow: none; /* no box shadow - for some browsers or if you are using Bootstrap */
  -webkit-tap-highlight-color: transparent;
}
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800&display=swap);

@-webkit-keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}

@-moz-keyframes rotate {
	0% {
		-moz-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	to {
		-moz-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}

@-o-keyframes rotate {
	0% {
		-o-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	to {
		-o-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}

@keyframes rotate {
	0% {
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg)
	}

	to {
		-webkit-transform: rotate(1turn);
		-moz-transform: rotate(1turn);
		-o-transform: rotate(1turn);
		transform: rotate(1turn)
	}
}

:not(input, textarea, select) {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
}

* {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
	-moz-box-sizing: border-box;
	box-sizing: border-box
}

body,
html {
	font-family: Nunito Sans, 微軟正黑體, Microsoft JhengHei, Microsoft JhengHei UI, Microsoft YaHei, Microsoft YaHei UI, PingFang TC, Lantinghei TC, Heiti TC, Apple LiGothic, PMingLiU, Arial, sans-serif;
	font-size: 16px;
	height: 100%;
	line-height: 1.5;
	margin: 0;
	min-width: 320px;
	padding: 0;
	width: 100%
}

#app {
	-moz-box-orient: vertical;
	-moz-box-direction: normal;
	-moz-box-align: center;
	-moz-box-pack: center;
	align-items: center;
	display: -moz-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100%;
	overflow: hidden;
	padding: 0 3.125rem
}

@media only screen and (max-width:575px) {
	#app {
		padding: 0 5%
	}
}

